import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpService } from './http.service';

import { IUser } from '../models/interfaces/IUser';
import { IDepartment } from '../models/interfaces/IDepartment';
import { IPendingUser } from '../models/interfaces/IPendingUser';
import { IDepartmentRank } from '../models/interfaces/IDepartmentRank';
import { IIpsUser } from '../models/interfaces/IIpsUser';
import { ICreateBubbleUserResponse } from '../models/interfaces/ICreateBubbleUserResponse';
import { AppDataService } from '../core/app-data.service';
import { IRole } from '../models/interfaces/IRole';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(private _httpService: HttpService, private _appData: AppDataService) { }

    getDepartmentsAsync(showAllDepts: boolean): Observable<IDepartment[]> {
        return this._httpService.sendGetAsync<IDepartment[]>(`user/GetDepartmentsAsync/${showAllDepts}`)
            .pipe(map((res) => res.body));
    }

    getDepartmentRanksAsync(deptId: number, includeReserves: boolean): Observable<IDepartmentRank[]> {
        return this._httpService.sendGetAsync<IDepartmentRank[]>(`user/GetDepartmentRanksAsync/${deptId}/${includeReserves}`)
            .pipe(map((res) => res.body));
    }

    getCurrentUser(): Observable<IUser> {
        return this._httpService.sendGetAsync<IUser>(`user/current`)
            .pipe(
                tap((res) => {
                    if (res.status === 401) {
                        this._appData.logOut();
                    } else {
                        this._appData.User = res.body as IUser;
                    }
                }),
                map((res) => res.body)
            );
    }

    authenticateUserAsync(email: string, password: string): Observable<IUser> {
        const body = {
            'emailAddress': email,
            'password': password
        };

        return this._httpService.sendPostAsync<IUser>(`auth/email`, body)
            .pipe(
                tap((res) => {
                    const token = res.headers.get(`X-DOJ-Token`);

                    if (token != null) {
                        this._appData.Token = token;
                    }
                }),
                map((data) => data.body)
            );
    }

    validateUserCredentialsAsync(email: string, password: string): Observable<boolean> {
        const body = {
            'emailAddress': email,
            'password': password
        };

        return this._httpService.sendPostAsync(`user/AuthenticateUserByEmailAsync`, body)
            .pipe(map((res) => res.ok));
    }

    /**
     * Seems to be left over from an old registration design
     * @deprecated
     */
    createPendingUserAsync(user: IPendingUser, password: string, secretPhrase: string): Observable<IPendingUser> {
        const body = {
            'user': user,
            'password': password,
            'secretPhrase': secretPhrase
        };

        return this._httpService.sendPostAsync<IPendingUser>(`user/CreatePendingUserAsync`, body)
            .pipe(map((data) => data.body));
    }

    setWhitelistStateAsync(userId: number, whiteListed: boolean, env: string): Observable<boolean> {
        const path: string = `user/SetServerWhitelistStateAsync/${userId}/${whiteListed}/${env}`;

        return this._httpService.sendGetAsync(path)
            .pipe(map((res) => res.ok));
    }

    getUsersAsync(searchTerm: string, includeDisabled: boolean, includeDeleted: boolean): Observable<IUser[]> {
        if (searchTerm) {
            return this._httpService.sendPostAsync<IUser[]>(`user/search/${includeDisabled}/${includeDeleted}`,
                { 'data': searchTerm })
                .pipe(map((data) => data.body));
        }
        else {
            return this._httpService.sendGetAsync<IUser[]>(`user/GetUsersAsync/${includeDisabled}/${includeDeleted}`)
                .pipe(map((data) => data.body));
        }
    }

    getPendingUsersAsync(): Observable<IPendingUser[]> {
        return this._httpService.sendGetAsync<IPendingUser[]>(`user/GetPendingUsersAsync`)
            .pipe(map((data) => data.body));
    }

    approvePendingUser(userId: number): Observable<boolean> {
        return this._httpService.sendPutAsync(`user/ApprovePendingUserAsync/${userId}`, null)
            .pipe(map((res) => res.ok));
    }

    denyPendingUser(userId: number): Observable<boolean> {
        return this._httpService.sendDeleteAsync(`user/DeletePendingUserAsync/${userId}`)
            .pipe(map((res) => res.ok));
    }

    createUserAsync(user: IUser, password: string): Observable<IUser> {
        const body = { 'user': user, 'password': password };
        return this._httpService.sendPostAsync<IUser>(`user/CreateUserAsync`, body)
            .pipe(map((res) => res.body));
    }

    updateUserAsync(user: IUser, password: string, updateDepts: boolean): Observable<IUser> {
        const body = { 'user': user, 'password': password };
        return this._httpService.sendPutAsync<IUser>(`user/UpdateUserAsync/${user.userId}/${updateDepts}`, body)
            .pipe(map((res) => res.body));
    }

    updateSelfUserAsync(user: IUser, password?: string): Observable<boolean> {
        const body = { 'user': user, 'password': password };
        return this._httpService.sendPutAsync(`user/self/${user.userId}`, body)
            .pipe(map((res) => res.ok));
    }

    getUserAsync(id: number): Observable<IUser> {
        return this._httpService.sendGetAsync<IUser>(`user/GetUserAsync/${id}`)
            .pipe(map((res) => res.body));
    }

    triggerWhitelistUpdateAsync(): Observable<boolean> {
        return this._httpService.sendGetAsync(`user/TriggerWhitelistUpdateAsync`)
            .pipe(map((res) => res.ok));
    }

    loadForumDataAsync(IpsUserId: number): Observable<IIpsUser> {
        return this._httpService.sendGetAsync<IIpsUser>(`ips/user/${IpsUserId}`)
            .pipe(map((res) => res.body));
    }

    getRolesAsync(): Observable<IRole[]> {
        return this._httpService.sendGetAsync<IRole[]>(`role/all`)
            .pipe(map((res) => res.body));
    }

    getRolesWithLevelAsync(level: number): Observable<IRole[]> {
        return this._httpService.sendGetAsync<IRole[]>(`role/all/${level}`)
            .pipe(map((res) => res.body));
    }

    getUserRolesAsync(userId: number): Observable<IRole[]> {
        return this._httpService.sendGetAsync<IRole[]>(`role/userroles/${userId}`)
            .pipe(map((res) => res.body));
    }

    addUserRoleAsync(roleId: number, userId: number): Observable<boolean> {
        return this._httpService.sendGetAsync(`role/user/${roleId}/${userId}`)
            .pipe(map((res) => res.ok));
    }

    removeUserRoleAsync(roleId: number, userId: number): Observable<boolean> {
        return this._httpService.sendDeleteAsync(`role/user/${roleId}/${userId}`)
            .pipe(map((res) => res.ok));
    }

    /**
     * @deprecated
     */
    async createCADAccount(userId: number, password: string): Promise<ICreateBubbleUserResponse> {
        const body = { 'userId': userId, 'password': password };

        const res = (await this._httpService.sendPostAsync(`bubble/user/create`, body).toPromise()) as
            HttpResponse<ICreateBubbleUserResponse>;

        return res.body;
    }

}
