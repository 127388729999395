import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IndividualConfig} from 'ngx-toastr/toastr/toastr-config';

/**
 * Screen notification (toast) service.  Uses ToastrService (ngx-toastr).  Site-wide defaults for
 * ngx-toastr are defined in app.module.ts, with the properties in this class used to override those
 * for different notification types.
 */
@Injectable({
  providedIn: 'root'
})
export class ScreenNotificationService {
  private _successConfig: Partial<IndividualConfig> = { timeOut: 8000, extendedTimeOut: 10000 };
  private _errorConfig: Partial<IndividualConfig> = { timeOut: 60000, extendedTimeOut: 60000 };
  private _warningConfig: Partial<IndividualConfig> = { timeOut: 10000, extendedTimeOut: 3000 };
  private _infoConfig: Partial<IndividualConfig> = { timeOut: 6000 };

  constructor(
    private _toastr: ToastrService)
  {
  }

  /**
   * Show a successful notification.
   *
   * @param msg Message text.
   * @param title Title of notification. Success notifications usually do NOT use a title. If
   * a title is used, it should not be a generic phrase (e.g. "Success") but rather it should
   * name the action or workflow that was successful.
   */
  public showSuccess(msg: string, title?: string): void
  {
    setTimeout(() => this._toastr.success(msg, title, this._successConfig));
  }

  /**
   * Show a failure/error notification.
   *
   * @param msg Message text.
   * @param title Title of notification. Optional but recommended. Title should not be a generic
   * phrase (e.g. "Success") but rather it should name the action or workflow that was successful.
   */
  public showError(msg: string, title?: string): void
  {
    setTimeout(() => this._toastr.error(msg, title, this._errorConfig));
  }

  /**
   * Show a warning notification.  Warning notifications are not common; to be used when
   * an action was successful (or partially successful) but with a warning.
   *
   * @param msg Message text.
   * @param title Title of notification. Optional but recommended. Title should not be a generic
   * phrase (e.g. "Success") but rather it should name the action or workflow that was successful.
   */
  public showWarning(msg: string, title?: string): void
  {
    setTimeout(() => this._toastr.warning(msg, title, this._warningConfig));
  }

  /**
   * Show an informational notification.  Informational notifications are not common; to be used when
   * an action was neither successful nor unsuccessful  (e.g. telling the user that an action is processing)
   * or when showing information to the user that is not based on their own actions (e.g. a different user did
   * some action).
   *
   * @param msg Message text.
   * @param title Title of notification. Optional but recommended. Title should not be a generic
   * phrase (e.g. "Success") but rather it should name the action or workflow that was successful.
   */
  public showInfo(msg: string, title?: string): void
  {
    setTimeout(() => this._toastr.info(msg, title, this._infoConfig));
  }
}
