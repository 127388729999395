import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavBarComponent } from '../components/navbar/navbar.component';
import { FooterComponent } from '../components/footer/footer.component';
import { DropdownDeptComponent } from '../components/controls/dropdown-dept/dropdown-dept.component';
import { DropdownRanksComponent } from '../components/controls/dropdown-ranks/dropdown-ranks.component';
import { SubdivisionsModalComponent } from '../components/controls/subdivisions-modal/subdivisions-modal.component';
import { RouterModule } from '@angular/router';
import { TruncatePipe } from './truncate.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FirstNamePipe } from './first-name.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatButtonToggleModule,
    FontAwesomeModule
  ],
  declarations: [
    NavBarComponent,
    FooterComponent,
    DropdownDeptComponent,
    DropdownRanksComponent,
    SubdivisionsModalComponent,
    TruncatePipe,
    LinkifyPipe,
    FirstNamePipe
  ],
  providers: [
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    DropdownDeptComponent,
    DropdownRanksComponent,
    TruncatePipe,
    LinkifyPipe,
    FirstNamePipe
  ]
})
export class SharedModule { 
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
  }
}
