import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { AppDataService } from '../../core/app-data.service';
import { IUserDepartment } from '../../models/interfaces/IUserDepartment';

@Injectable({
  providedIn: 'root'
})
export class AuthCadGuard implements CanActivateChild {

  constructor(private _router: Router, private _appData: AppDataService) {
  }

  canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
    const user = this._appData.User;
    let gameDepts: IUserDepartment[] = [];

    if (user.departments && user.departments.length > 0) {
      gameDepts = user.departments.filter((d) => d.department && d.department.inGameDepartment);
    }

    if (gameDepts && gameDepts.length > 0) {
      return true;
    }
    else {
      return this.redirectToHome(`You have no departments - contact your chain of command for assistance.`);
    }
  }

  private redirectToHome(msg: string): UrlTree {
    alert(msg);
    return this._router.parseUrl('/user/home');
  }

}
