<nav class="navbar-header mat-elevation-z6">
  <h1 class="main-title" routerLink="/user/home">DoJRP Portal</h1>
  <span class="menu-spacer"></span>
  <a mat-button routerLink="/user/home">Home</a>

  <a mat-button routerLink="/user/patrol">Patrol</a>

  <a mat-button routerLink="/user/account-settings">My Account</a>

  <a mat-button href="javascript:;" [matMenuTriggerFor]="adminMenu" *ngIf="isAdmin">Admin <mat-icon>arrow_drop_down
    </mat-icon></a>
  <mat-menu #adminMenu="matMenu">
    <button mat-menu-item routerLink="/admin/manage-users">Manage Users</button>
    <button mat-menu-item routerLink="/admin/add-user">Add User</button>
    <button mat-menu-item routerLink="/admin/manage-servers">Manage Servers</button>
    <button mat-menu-item routerLink="/admin/api-logs">Framework Logs</button>
    <button mat-menu-item routerLink="/admin/portal-logs" *ngIf="isDev">Portal Logs Viewer</button>
  </mat-menu>


  <a mat-button href="javascript:;" [matMenuTriggerFor]="staffMenu" *ngIf="isStaff">Staff <mat-icon>arrow_drop_down
    </mat-icon></a>
  <mat-menu #staffMenu="matMenu">
    <button mat-menu-item routerLink="/staff/members">Manage Members</button>
    <button mat-menu-item routerLink="/staff/records">Recent Records</button>
    <!-- <button mat-menu-item routerLink="/staff/servers">Manage Servers</button> --> <!-- Functionality is replaced by additional controls on the front page -->
    <!-- <button mat-menu-item routerLink="/staff/priority" *ngIf="isCivPrimary || isAdmin">Manage Priorities</button> --> <!-- Disabled due to instability on the FiveM side -->
  </mat-menu>

  <a mat-button routerLink="/warrants" *ngIf="isBWAU">Manage Warrants</a>

  <a mat-button href="javascript:;" [matMenuTriggerFor]="lmMenu" *ngIf="shouldShowLivemap && !isTablet">Livemap
    <mat-icon>arrow_drop_down</mat-icon></a>
  <mat-menu #lmMenu="matMenu">
    <button *ngFor="let server of servers" mat-menu-item routerLink="/map"
      [queryParams]="{server: server.serverId}">{{ server.serverName }}</button>
  </mat-menu>

  <a mat-button href="javascript:;" (click)="logout()">
    Logout
    <!-- Half space -->
    &#8239;
    <mat-icon>exit_to_app</mat-icon>
  </a>
</nav>

<!-- Snow Month Theme
<div class="snow">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
-->