import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateChild } from '@angular/router';
import { AppDataService } from '../../core/app-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthWarrantsGuard implements CanActivateChild {

  constructor(private _router: Router, private _appData: AppDataService) { }

  canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
    if (this._appData.isUserBWAU()) {
      return true;
    }
    else {
      return this._router.parseUrl('/user/home');
    }
  }

}
