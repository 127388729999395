import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoggingHelper } from '../../core/logging/helper';
import { ISettingsSink } from '../../models/interfaces/ISettingsSink';
import { IdbSettingsSink } from './sinks/idb-settings-sink';
import { LocalSettingsSink } from './sinks/local-settings-sink';
/**
 * Provides an interface to set and retrieve persistent settings.
 * Internally it stores all settings in memory and has interfaces for saving/loading settings
 * It automatically saves every 5 minutes as well as on destruction
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService extends LoggingHelper implements OnDestroy {

  /**
   * All sinks that will be used to load/save settings
   * index 0 is saved to first and loaded from last
   */
  private _sinks: ISettingsSink[];

  private _settings: Record<string, any> = {};

  private _destroyed$ = new Subject();

  private _currentSettingsSrc = new BehaviorSubject<Record<string, any>>({});
  currentSettings = this._currentSettingsSrc.asObservable();

  constructor() {
    super();

    if (!IDBTransaction) {
      this.logWarning('Cannot detect IDB browser support, falling back to local storage...');
      this._sinks = [new LocalSettingsSink()];
    } else {
      this._sinks = [new IdbSettingsSink()];
    }

    // Expose to browser for debugging
    if (!environment.production) {
      window['DojrpServices'] = { ...window['DojrpServices'], SettingsService: this };
    }

    // Load from all sinks
    this.loadFromAll();

    // Save to all sinks every five minutes (starting in 5 minutes)
    interval(1000 * 60 * 2).pipe(takeUntil(this._destroyed$)).subscribe((_) => this.saveToAll());
  }

  ngOnDestroy() {
    // Prevent a memory leak by destroying the auto-save timer and triggering a manual save
    this._destroyed$.next();

    this.saveToAll();

    // tslint:disable-next-line: no-unsafe-any
    if (window['DojrpServices']?.['SettingsService'] === this) {
      // tslint:disable-next-line: no-unsafe-any
      delete window['DojrpServices']['SettingsService'];
    }
  }

  /**
   * Sets `key` to `value` in the local settings store
   *
   * This will not be immediately saved to the sinks. It it is automatically saved every five minutes,
   * or can be manually saved with `saveToAll()`
   *
   * @param key the settings key
   * @param value the new value
   */
  setSetting(key: string, value: any) {
    if (typeof key !== 'string') {
      this.logError(`Cannot set setting, ${key} is not a string`);
      return;
    }

    this._settings[key] = value;

    // Make sure any subscriptions get the new value
    this._currentSettingsSrc.next(this._settings);
  }

  /**
   * Retrieves a value from the settings store, or undefined if it does not exist
   * @param key the settings key
   */
  getSetting<T>(key: string): T | undefined;
  /**
   * Retrieves a value from the settings store, or `defaultValue` if it does not exist
   * @param key the settings key
   * @param defaultValue The default value to use if key is not defined
   */
  getSetting<T>(key: string, defaultValue: T): T;
  getSetting<T>(key: string, defaultValue?: T): T | undefined {
    if (this._settings[key]) {
      return this._settings[key] as T;
    } else if (defaultValue) {
      this.setDefaultValue(key, defaultValue);
      return defaultValue as T;
    } else {
      return undefined;
    }
  }

  /**
   * Listen to updates to a settings value
   *
   * @param key the settings key
   */
  listenToSetting<T>(key: string): Observable<T | undefined> {
    return this.currentSettings.pipe(map((settings) => settings[key] as T), distinctUntilChanged());
  }

  /**
   * Sets `key` to `defaultValue` if it is currently not stored, otherwise it keeps it at its current value
   */
  setDefaultValue(key: string, defaultValue: any) {
    if (!this._settings[key]) {
      this.setSetting(key, defaultValue);
    }
  }

  /**
   * Saves the settings to all sinks (to disk/API), starting at the lowest index
   */
  saveToAll() {
    for (let i = 0; i < this._sinks.length; i++) {
      this._saveToSink(i);
    }
  }

  /**
   * Saves settings to sink at `index`
   * @param index The index of the sink to save to
   * @private
   */
  private _saveToSink(index: number) {
    const sink = this._sinks[index];
    if (sink) {
      sink.save(this._settings);
    } else {
      this.logWarning(`Cannot save to settings sink index ${index} as it does not exist`);
    }
  }

  /**
   * Loads settings from all sinks, starting at the highest index
   */
  loadFromAll() {
    for (let i = this._sinks.length - 1; i >= 0; i--) {
      this._loadFromSink(i);
    }
  }

  /**
   * Loads settings from sink at `index`
   * @param index The index of the sink to load from
   * @private
   */
  private _loadFromSink(index: number) {
    const sink = this._sinks[index];
    if (sink) {
      const loaded = sink.load();
      if (loaded instanceof Promise) {
        void loaded.then((settings) => this._applyLoadedSettings(settings));
      } else {
        this._applyLoadedSettings(loaded);
      }
    } else {
      this.logWarning(`Cannot load from settings sink index ${index} as it does not exist`);
    }
  }

  /**
   * Applies a given settings object to the cached settings in memory
   * Used when a sink loads settings to prevent data loss and to send out the proper notifications
   */
  private _applyLoadedSettings(newSettings: Record<string, any>) {
    this._settings = { ...this._settings, ...newSettings };

    this._currentSettingsSrc.next(this._settings);
  }
}
