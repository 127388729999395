import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDepartmentSubdivision } from '../../../../models/interfaces/IDepartmentSubdivision';
import { MatTableDataSource } from '@angular/material/table';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material/button-toggle';
import { ScreenNotificationService } from '../../../../services/screen-notification.service';

@Component({
  selector: 'app-subdivisions-modal',
  templateUrl: './subdivisions-modal.component.html',
  styleUrls: ['./subdivisions-modal.component.scss']
})
export class SubdivisionsModalComponent implements OnInit {
  readonly columns = ['glyph', 'name', 'actions'];
  readonly dataSource = new MatTableDataSource<IDepartmentSubdivision>();

  selections: {[key: number]: string} = {};

  @ViewChildren(MatButtonToggle) _toggleButtons: QueryList<MatButtonToggle>;

  constructor(
    public dialogRef: MatDialogRef<SubdivisionsModalComponent>,
    private _scrnNotif: ScreenNotificationService,
    @Inject(MAT_DIALOG_DATA) public data: { subdivisions: IDepartmentSubdivision[], allSubdivisions: IDepartmentSubdivision[], selectedSubdivisions: BigInt, activeId: number },) { }

  ngOnInit(): void {
    this.dataSource.data = this.data.subdivisions;

    for (let sub of this.data.subdivisions) {
      if (sub.subdivisionId == this.data.activeId) this.selections[sub.subdivisionId] = 'active';
      // @ts-ignore
      else if ((this.data.selectedSubdivisions & BigInt(1n << BigInt(sub.subdivisionId))) == BigInt(1n << BigInt(sub.subdivisionId))) this.selections[sub.subdivisionId] = 'standby';
      else this.selections[sub.subdivisionId] = 'inactive';
    }
  }

  statusChange(change: MatButtonToggleChange, id: number) {
    let activeCount = 0;
    let standbyCount = 0;

    for (let sel of Object.values(this.selections)) {
      if (sel == "active") {
        activeCount++;
      } else if (sel == "standby") {
        standbyCount++;
      }
    }

    if (this.selections[id] == "active" && change.value !== "active") {
      activeCount--;
    } else if (change.value == "active" && this.selections[id] !== "active") {
      activeCount++;
    }

    if (this.selections[id] == "standby" && change.value !== "standby") {
      standbyCount--;
    } else if (change.value == "standby" && this.selections[id] !== "standby") {
      standbyCount++;
    }

    if (activeCount > 1) {
      change.source.buttonToggleGroup.value = this.selections[id];
      this._scrnNotif.showWarning("You can only have one active subdivision at a time.");
    } else if (standbyCount > 4) {
      change.source.buttonToggleGroup.value = this.selections[id];
      this._scrnNotif.showWarning("You can only have up to four standby subdivisions at a time.");
    } else {
      this.selections[id] = change.value;
    }
  }

  deactivateAll() {
    for (let sub of this.data.subdivisions) {
      this.selections[sub.subdivisionId] = 'inactive';
    }

    for (let button of this._toggleButtons.toArray()) {
      button.buttonToggleGroup.value = 'inactive';
    }
  }

  save() {
    let activeSubdivision = this.data.allSubdivisions.filter(sub => this.selections[sub.subdivisionId] == 'active')[0];
    let standbySubdivisions = this.data.allSubdivisions.filter(sub => this.selections[sub.subdivisionId] == 'standby');

    let availableSubdivisionFlags = BigInt(0);

    // Trigger bit flags based on subdivision ID
    for (let sub of standbySubdivisions) {
      // @ts-ignore
      availableSubdivisionFlags |= BigInt(1n << BigInt(sub.subdivisionId));
    }

    this.dialogRef.close({ activeSubdivision, availableSubdivisionFlags });
  }
}
