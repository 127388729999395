import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  /**
   * Truncates the given string value to the given length
   * @param value the string value to truncate
   * @param length the length to truncate to (defaults to 20)
   */
  transform(value: string, length?: number): string {
    const limit = isNaN(length) ? 20 : length;
    const trail = '…';
    return value.length > limit ? value.substring(0, limit).trim() + trail : value;
  }

}
