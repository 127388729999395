import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDataService } from './app-data.service';
import { LoggingService } from './logging/logging.service';
import { GlobalErrorHandler } from './logging/error-handler';
import { Error404Component } from './components/views/error-404/error-404.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    Error404Component
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AppDataService,
        LoggingService, {
          // processes all errors
          provide: ErrorHandler,
          useClass: GlobalErrorHandler
        }
      ]
    };
  }
}
