import { IDBPDatabase, openDB } from 'idb';
import { ISettingsSink } from '../../../models/interfaces/ISettingsSink';
import { LoggingHelper } from '../../../core/logging/helper';
import { LoggingService } from '../../../core/logging/logging.service';

const DB_NAME = 'settings';
const SETTINGS_STORE_NAME = 'settings';

export class IdbSettingsSink extends LoggingHelper implements ISettingsSink {
  private _db: IDBPDatabase;
  private _dbInit = false;
  private _preDbSettings: Record<string, any>[] = [];

  constructor() { super(); void this._initDatabase(); }

  async save(settings: Record<string, any>): Promise<void> {
    if (this._dbInit) {
      await this._saveSettings(settings);
    } else {
      this._preDbSettings.push(settings);
    }
  }

  load(): Promise<Record<string, any>> {
    return this._loadSettings();
  }

  /**
   * Internal Functions
   */
  private async _initDatabase() {
    this._db = await openDB(DB_NAME, 1, {
      upgrade(db, oldV, newV, _tx) {
        if (oldV === 0) {
          db.createObjectStore(SETTINGS_STORE_NAME);
        } else {
          LoggingService.instance.error('IdbSettingsSink', 'Unknown database migration attempted from ', oldV, ' to ', newV, '. Creating fresh database...');
        }
      },
      blocked() { },
      blocking() { },
      terminated() { }
    });

    this._dbInit = true;

    // If any logs should have been stored, store them now
    if (this._preDbSettings.length > 0) {
      for (const settings of this._preDbSettings) {
        await this.save(settings);
      }

      this._preDbSettings = [];
    }
  }

  private async _saveSettings(settings: Record<string, any>): Promise<void> {
    for (const settingKey of Object.keys(settings)) {
      await this._saveSetting(settingKey, settings[settingKey]);
    }
  }

  private async _saveSetting(key: string, value: any): Promise<void> {
    const tx = this._db.transaction(SETTINGS_STORE_NAME, 'readwrite');
    const store = tx.objectStore(SETTINGS_STORE_NAME);

    await store.put(value, key);

    await tx.done;
  }

  private async _loadSettings(): Promise<Record<string, any>> {
    // Wait until database is loaded
    await waitFor(() => this._dbInit);

    const tx = this._db.transaction(SETTINGS_STORE_NAME, 'readonly');
    const store = tx.objectStore(SETTINGS_STORE_NAME);

    const settings: Record<string, any> = {};

    const keys = await store.getAllKeys();

    // Iterate over each key, converting all of the keys to a string
    for (const key of keys.map((k) => `${k}`)) {
      settings[key] = await store.get(key);
    }

    return settings;
  }

}

/**
 * Helper polling functions (not exported so they are local to this file)
 */

/**
 * Sleeps for given time
 * @param ms the time in milliseconds
 */
function sleep(ms: number) { return new Promise((resolve) => setTimeout(resolve, ms)); }

/**
 * Polls `test` until it is truthy or the timeout passes
 * @param test The test to perform
 * @param timeout_ms The timeout, defaults to 20 seconds
 * @returns -- true if successful, false if it times out
 */
async function waitFor(test: () => boolean, timeout_ms = 20 * 1000): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    const freq = 100;
    let result: boolean | null | undefined;
    // wait until the result is truthy, or timeout
    while (!result) {
      timeout_ms -= freq;
      if (timeout_ms < 0) {
        reject('Timed out');
        return;
      }
      await sleep(freq);
      result = test();       // run the test and update result variable
    }
    // return result if test passed
    resolve(result);
  });
}
