import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EDepartment } from '../models/enums/department.enum';

import { IUser } from '../models/interfaces/IUser';
import { SettingsService } from '../services/settings/settings.service';

const _cDefaultCadTheme: string = 'cad-dark-theme';
const _cDefaultServerDisplay: boolean = false;

const CAD_THEME_KEY = 'cadTheme';
const SERVER_DISPLAY_KEY = 'displayServer';

@Injectable()
export class AppDataService implements OnDestroy {

  private user: IUser = null;

  adminForwardPage: string = `/admin/manage-users`;
  public cadTheme: string = this._settings.getSetting(CAD_THEME_KEY, _cDefaultCadTheme);
  public cadThemeObs: BehaviorSubject<string> = new BehaviorSubject<string>(_cDefaultCadTheme);

  public serverDisplay = this._settings.getSetting(SERVER_DISPLAY_KEY, _cDefaultServerDisplay);
  public serverDisplayObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(_cDefaultServerDisplay);

  private _destroyed$ = new Subject();

  constructor(private _router: Router, private _settings: SettingsService) {
    this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        if (ev.url.includes('tablet=true')) {
          this.isTablet = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  /**
   * The current user's information
   */
  get User(): IUser {
    return this.user;
  }

  set User(user: IUser) {
    this.user = user;
  }

  /**
   * The current authorization token
   */
  get Token(): string {
    return localStorage['token'] as string;
  }

  set Token(token: string) {
    localStorage['token'] = token;
  }

  /**
   * If the portal is currently in tablet mode
   */
  get isTablet(): boolean {
    return localStorage['isTablet'] === 'true';
  }

  set isTablet(isTab: boolean) {
    localStorage['isTablet'] = isTab;
  }

  /**
   * Toggle for Search Tones when you run a name or phase
   */
  getSearchTonesPlayable(): string {
    const booleanData = localStorage.getItem('searchTonesPlayable')
    return booleanData
  }

  toggleSearchTonesPlayable(searchT: string) {
    if(searchT === "true") {
      localStorage.setItem('searchTonesPlayable', searchT)
    } else {
      localStorage.removeItem('searchTonesPlayable')
    }

    return searchT
  }


  /**
   * Logs out the user and redirects to the login page
   */
  logOut(): void {
    localStorage.removeItem('token');
    this.User = null;

    void this._router.navigate(['login']);
  }

  /**
   * @returns if the user is an administrator
   */
  isUserAdmin(): boolean {
    let admin: boolean = false;

    if (this.user && this.user.isAdmin) {
      admin = true;
    }

    return admin;
  }

  /**
   * @returns if the user is Staff+
   */
  isUserStaff(): boolean {
    let staff: boolean = false;

    if (this.user) {
      if (this.isUserSeniorStaff() || this.user.roles.includes('STAFF')) {
        staff = true;
      }
    }

    return staff;
  }

  /**
   * @returns if the user is Senior Staff+
   */
  isUserSeniorStaff(): boolean {
    let staff: boolean = false;

    if (this.user) {
      if (this.isUserAdmin() || this.user.roles.includes('SENIOR_STAFF')) {
        staff = true;
      }
    }

    return staff;
  }

  /**
   * @returns if the user is staff+ and in a LEO department full time
   */
  isUserLEOSupervisor(): boolean {
    if (this.isUserStaff()) {
      const primaryDept = this.user.departments.find((dept) => dept.isPrimary);

      if (primaryDept.departmentId === EDepartment.LSPD
        || primaryDept.departmentId === EDepartment.SAHP
        || primaryDept.departmentId === EDepartment.BCSO) {
        return true;
      }
    }

    return false;
  }

  /**
   * @returns if the user is apart of Judicial Services
   */
  isUserBWAU(): boolean {
    if (this.user && this.user.permissions.includes('JS_WARRANTS')) {
      return true;
    }

    return false;
  }

  /**
   * @returns if the user is a Judicial Services supervisor
   */
  isUserWarrantSupervisor(): boolean {
    if (this.user && this.user.permissions.includes('JS_WARRANTS_SUPERVISOR')) {
      return true;
    }

    return false;
  }

  /**
   * Toggles the current CAD theme
   */
  toggleCadTheme(): void {
    if (this.cadTheme === 'cad-light-theme') {
      this.setCadTheme('cad-dark-theme');
    } else {
      this.setCadTheme('cad-light-theme');
    }
  }

  /**
   * Sets the current CAD theme and serializes it to settings
   * @param name the theme to set
   */
  private setCadTheme(name: string) {
    this.cadTheme = name;
    this.cadThemeObs.next(name);
    this._settings.setSetting(CAD_THEME_KEY, name);
  }

/**
 * Gets the material color for the given role
 * @param role the role label
 */
  getRoleChipColor(role: string): string {
    if (role === `Super Administrator`) {
      return 'primary';
    } else if (role === `Administrator`) {
      return 'warn';
    } else if (role === 'Staff') {
      return 'accent';
    }

    return null;
  }

  /**
   * Toggles the current server display in CAD
   */
  toggleServerDisplay(): void {
    if (this.serverDisplay) {
      this.setServerDisplay(false);
    } else {
      this.setServerDisplay(true);
    }
  }

  /**
   * Sets if the current server should be displayed, and serializes that value to settings
   * @param value if the current server should be saved
   */
  private setServerDisplay(value: boolean) {
    this.serverDisplay = value;
    this.serverDisplayObs.next(value);
    this._settings.setSetting(SERVER_DISPLAY_KEY, value);
  }

  /**
   * @returns if the portal is running in a local debug session
   */
  isLocalDebug() {
    return !environment.production && (location.hostname === 'localhost' || location.hostname === '127.0.0.1');
  }

}
